'use client'

import * as React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronDown } from 'lucide-react'

import { cn } from '@/lib/utils'

const AccordionRoot = AccordionPrimitive.Root

const Accordion = React.forwardRef<
  React.ElementRef<typeof AccordionRoot>,
  React.ComponentPropsWithoutRef<typeof AccordionRoot>
>(({ className, ...props }, ref) => (
  <AccordionRoot
    ref={ref}
    className={cn('w-full max-w-screen-xl', className)}
    {...props}
  />
))
Accordion.displayName = 'Accordion'

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn(
      'border border-b-0 border-tertiary-form-border bg-accent',
      'first:rounded-t-lg',
      'last:rounded-b-lg last:border-b',
      className
    )}
    {...props}
  />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex flex-1 items-center justify-between py-3 px-4 md:py-3 md:px-6 lg:py-4',
        'group',
        'focus-visible:border-accessibility-ring focus-visible:shadow-ring focus-visible:outline-none',
        'focus-visible:ring-accessibility-ring',
        className
      )}
      {...props}
    >
      <div className="grow text-left">{children}</div>
      <div className="shrink-0 pl-6">
        <ChevronDown className="h-6 w-4 stroke-[5] text-primary-button transition-transform duration-200 group-hover:text-primary-button-hover group-focus-visible:text-primary-button-focused group-active:text-primary-button-pressed group-data-[state=open]:rotate-180" />
      </div>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'prose body-md-regular overflow-hidden border-t border-tertiary-form-border p-6 text-accent-foreground transition-all prose-a:body-md-regular data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down prose-a:text-primary prose-a:underline',
      className
    )}
    {...props}
  >
    {children}
  </AccordionPrimitive.Content>
))
AccordionContent.displayName = AccordionPrimitive.Content.displayName

const AccordionTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      'body-lg-bold text-left text-primary-button p-1',
      'transition-colors',
      'group-hover:text-primary-button-hover group-hover:underline',
      'group-focus-visible:text-primary-button-focused group-focus-visible:underline',
      'group-active:text-primary-button-pressed group-active:underline',
      'group-disabled:text-primary-button-disabled',
      className
    )}
    {...props}
  />
))
AccordionTitle.displayName = 'AccordionTitle'

const AccordionSubtitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('body-lg-regular text-accent-foreground pl-1', className)}
    {...props}
  />
))
AccordionSubtitle.displayName = 'AccordionSubtitle'

const AccordionHeaderContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('flex flex-col gap-2', className)} {...props}>
    {children}
  </div>
))
AccordionHeaderContent.displayName = 'AccordionHeaderContent'

export {
  Accordion,
  AccordionContent,
  AccordionHeaderContent,
  AccordionItem,
  AccordionSubtitle,
  AccordionTitle,
  AccordionTrigger,
}
