export enum Vendor {
  Wellthy = 'Wellthy',
  OsaraHealth = 'Osara Health',
  Pelago = 'Pelago',
  ComPsych = 'ComPsych',
  UpriseHealth = 'Uprise Health',
  EstateGuidance = 'Estate Guidance',
  Willprep = 'Willprep',
  AssistAmerica = 'Assist America',
  Travelaid = 'Travelaid',
  SpringHealth = 'Spring Health',
}

type Benefit = {
  benefit: string
  details: string
  vendor: Vendor
  label?: string
}

export type Wellness = {
  isEnterprise: boolean
  legalStateCd: string
  benefits: Benefit[]
} | null

export type WellnessResponse = {
  data: Wellness
  error: string | null
}
