import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-lg hover:underline focus-visible:shadow-ring focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accessibility-ring active:underline disabled:pointer-events-none',
  {
    variants: {
      variant: {
        primary:
          'bg-primary-button text-primary-foreground shadow-sm hover:bg-primary-button-hover focus-visible:bg-primary-button-focused active:bg-primary-button-pressed disabled:bg-primary-button-disabled',
        'primary-outline':
          'bg-transparent text-primary ring-2 ring-primary-button hover:text-primary-button-hover hover:ring-primary-button-hover active:text-primary-button-pressed active:ring-primary-button-pressed disabled:text-primary-button-disabled disabled:ring-primary-button-disabled',
        'primary-accordion-link':
          'body-md-bold gap-2 rounded-sm bg-transparent p-1 text-primary no-underline hover:text-primary-button-hover hover:underline focus-visible:underline active:text-primary-button-pressed active:underline disabled:text-primary-button-disabled disabled:underline',
        'primary-breadcrumb-link':
          'body-md-bold gap-2 rounded-sm bg-transparent p-1 text-primary underline hover:text-primary-button-hover hover:no-underline active:text-primary-button-pressed active:no-underline disabled:text-primary-button-disabled',
        secondary:
          'support-styles-cta-tomato gap-3 bg-secondary-button px-4 py-2 text-secondary-foreground shadow-sm hover:bg-secondary-button-hover focus-visible:bg-secondary-button-focused active:bg-secondary-button-pressed disabled:bg-secondary-button-disabled',
        'secondary-link':
          'support-styles-cta-tomato gap-2 rounded-sm bg-transparent p-1 text-secondary underline hover:text-secondary-button-hover hover:no-underline active:text-secondary-button-pressed active:no-underline disabled:text-secondary-button-disabled',
        'carousel-indicator':
          'focus-visible:ring-none flex items-center justify-center rounded-full bg-background text-foreground ring-1 ring-secondary-form-border transition-all hover:ring-foreground focus-visible:bg-background focus-visible:text-foreground focus-visible:shadow-ring focus-visible:ring-2 focus-visible:ring-accessibility-ring',
        'carousel-advanced':
          'focus-visible:ring-none rounded-full bg-secondary text-base-white transition-all hover:bg-background hover:text-secondary hover:ring-2 hover:ring-secondary focus-visible:bg-background focus-visible:text-secondary focus-visible:shadow-ring focus-visible:ring-2 focus-visible:ring-accessibility-ring disabled:opacity-50',
      },
      size: {
        mini: 'gap-2 p-2',
        'circle-xs': 'size-5',
        'circle-sm': 'size-6',
        'circle-lg': 'size-[44px] p-2.5',
      },
    },
    compoundVariants: [
      {
        variant: ['primary', 'primary-outline'],
        class: 'support-styles-cta-bold px-3 py-2 lg:gap-3 lg:px-4 lg:py-2',
      },
      {
        variant: ['carousel-indicator', 'carousel-advanced'],
        class: 'support-styles-cta-bold hover:no-underline active:no-underline',
      },
    ],
    defaultVariants: {
      variant: 'primary',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
